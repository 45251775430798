import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { TurnoService } from 'src/app/core/auth/turno.service';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { FormControlService } from '../../services/form-control.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { VentaService } from 'src/app/modules/venta';
import { ToastrService } from 'ngx-toastr';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { InfoToast } from 'src/app/core/toast/info.toast';
import { mergeMap, map, takeWhile, takeUntil } from 'rxjs/operators';
import { PrintService } from '../../services/print.service';
import { MyCurrencyPipe } from '../../pipes/myCurrency.pipe';
import * as moment from 'moment';
import 'moment/min/locales';
import { ConfigurationService } from 'src/app/core';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from 'src/app/app.configuration';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ticketing-cerrar-turno',
  templateUrl: './cerrar-turno.component.html',
  styleUrls: ['./cerrar-turno.component.scss']
})
export class CerrarTurnoComponent implements OnInit, OnDestroy {
  datosInforme;
  usuarios;
  productosVenta;
  comentarios;
  currentDate = moment().format('YYYY-MM-DD HH:mm');
  private alive = true;
  window = window as any;
  disabledAceptar = false;
  cerrarTurnoSub: Subscription
  cierreTurno: FormGroup;
  @Input() id: string;
  @ViewChild('efectivo') clientInput: ElementRef;
  private element: any;
  divisaSimboloDecimal: string;
  divisaSimbolo: string;
  divisaDecimales: number;
  divisaPosicion: string;
  esGranDivisa: boolean;

  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    private turnoService: TurnoService,
    private userService: UserManagerService,
    private confirmationService: ConfirmationService,
    private ventaService: VentaService,
    private toast: ToastrService,
    private printService: PrintService,
    private currency: MyCurrencyPipe,
    private configuration: ConfigurationService,
    private http: HttpClient,
    private config: AppConfiguration,
    private configurationService: ConfigurationService,
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.divisaSimboloDecimal = this.configurationService.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configurationService.divisasValue[0].simbolo;
    this.divisaDecimales = Number(this.configurationService.divisasValue[0].NumeroDecimales)
    this.divisaPosicion = this.configurationService.divisasValue[0].PosicionDivisa;
    this.esGranDivisa = this.configurationService.divisasValue[0].CodIso = "188" ? true: false;
    this.cierreTurno = this.formBuilder.group({
      efectivo: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]+(.[0-9]{0,2})?')
        ])
      ],
      // bolsaCambio: [
      //   '',
      //   Validators.compose([
      //     Validators.maxLength(10),
      //     Validators.pattern('^[a-zA-Z0-9]*$')
      //   ])
      // ],
      comentario: [
        '',
        Validators.compose([
          Validators.maxLength(50),
          Validators.pattern('^[a-zA-Z0-9á-ý\\u00f1\\u00d1\\s]*$')
        ])
      ]
    });

    const modal = this;

    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed abovse everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
    // this.onChanges();
  }
  ngOnDestroy() {
    this.formControlService.unfocus();
  }

  open(): void {
    this.alive = true;
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.alive = false;
    this.disabledAceptar = false;
    this.formControlService.unfocus();
    if(this.cerrarTurnoSub){this.cerrarTurnoSub.unsubscribe();}
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');

  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.cierreTurno,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  deleteValue() {
    this.formControlService.setToBlank();
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
      element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }

  cerrarTurno() {
    if (this.checkFormIsInvalid()) {
      return;
    }
    this.disabledAceptar = true;
    const efectivo =
      Number.parseFloat((this.cierreTurno.controls['efectivo'].value).replace(',','.')) || 0;
    this.comentarios = this.cierreTurno.controls['comentario'].value || '';
    this.modalService.open('confirmarCierreTurno');
    this.cerrarTurnoSub = this.confirmationService.confirmado
      .subscribe(confirmado => {
        // 
        if (confirmado) {
          this.turnoService
            .cerrarCaja(efectivo, this.comentarios)
            .pipe(
              mergeMap(res => {
                return this.turnoService
                  .generarInformeCierre(this.turnoService.cajaValue.CajaId)
                  .pipe(
                    map((datosInforme: any) => {
                      return { ...res, datosInforme: datosInforme.DatosResult };
                    })
                  );
              })
            )
            .pipe(takeWhile(() => this.alive))
            .subscribe(async (res: any) => {
              if (!res.DatosResult) {
                this.closeModals();
                this.toast.error(res.Mensajes[0].DescripcionMensaje, 'Error', {
                  toastComponent: ErrorToast,
                  timeOut: 5000
                });
                this.cerrarTurnoSub.unsubscribe();
                return;
              }      
              this.turnoService.getDatosTPVPath().subscribe();  
              this.datosInforme = res.datosInforme;
              this.usuarios = res.datosInforme? res.datosInforme.ListadoCajaUsuarios: null;
              this.productosVenta = res.datosInforme? res.datosInforme.ListadoCajaProductos: null;
              this.closeModals();
              this.reset();
              this.turnoService.setTurnoValue(undefined);
              this.toast.info('', 'Caja cerrada', {
                toastComponent: InfoToast,
                timeOut: 5000
              });
              this.configuration.dataReady.next(true);
              //Metemos un bucle segun configuracion 
              let cantidadImpresiones = 0;
              if (this.configuration.deviceConfiguration.AppConfigJsonTurno && this.configuration.deviceConfiguration.AppConfigJsonTurno.CopiasCierre != null && this.configuration.deviceConfiguration.AppConfigJsonTurno.CopiasCierre.trim() != '')
              {
                cantidadImpresiones = this.configuration.deviceConfiguration.AppConfigJsonTurno && this.configuration.deviceConfiguration.AppConfigJsonTurno.CopiasCierre? Number.parseInt(this.configuration.deviceConfiguration.AppConfigJsonTurno.CopiasCierre): 0;
                
                
              }             
              //Cargamos de configuracion
              setTimeout(() => {
                if(cantidadImpresiones > 0){
                  for (let i = 0; i < cantidadImpresiones; i++) {
                    this.printService.printCashClosureReport(this.datosInforme);
                  }
                }               
              }, 5000);
              if (
                this.window.external &&
                typeof this.window.external.salir === 'function'
              ) {
                //Prueba con delay
                await this.delay(10000);
                this.window.external.salir();
              }
              this.cerrarTurnoSub.unsubscribe();
            });
        }
      });
  }

  closeModals() {
    this.modalService.close('confirmarCierreTurno');
    this.modalService.close('cerrarTurno');
    this.modalService.close('gestionarTurno');
    this.close();
  }

  reset() {
    
    //Cargamos el tpv original 
    const config = this.configuration.getTPVPathOrigenValue();    
    this.configuration.setTpvPathLocal(config);
    //fin de cargamos tpv original
    this.userService.logout();
    this.ventaService.clearCarrito();
    this.turnoService.setTurnoValue(undefined);
    this.confirmationService.setConfirmado(false);
    this.userService.setCurrentUser(null);
    this.turnoService.setCambioValue(null);
    this.turnoService.setTurnoValue(null);
  }

  checkFormIsInvalid() {
    if (this.cierreTurno && this.cierreTurno.invalid) {
      return true;
    }
    if(this.disabledAceptar)
    {
      return true;
    }
    return false;
  }

  calculaPrecioUnitario(total, cantidad) {
    const unidad =
      Number.parseFloat(total.replace(',', '.')) /
      Number.parseFloat(cantidad.replace(',', '.'));
    return this.currency.transform(unidad, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo);
  }
  calculaTotalNumVentas() {
    let total = 0;
    this.productosVenta.forEach(producto => {
      total = total + Number(producto.ProductoCantidadVenta);
    });
    return this.currency.transform(total, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo);
  }

  calculaTotalDevoluciones() {
    let total = 0;
    this.productosVenta.forEach(producto => {
      total = total + Number(producto.ProductoCantidadDevolucion);
    });
    return this.currency.transform(total, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo);
  }

  calculaTotalReservas() {
    let total = 0;
    this.productosVenta.forEach(producto => {
      total = total + Number(producto.ProductoCantidadReserva);
    });
    return this.currency.transform(total, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo);
  }

  calculaCantidadTotal() {
    let total = 0;
    this.productosVenta.forEach(producto => {
      total = total + Number(producto.ProductoCantidad);
    });
    return this.currency.transform(total, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo);
  }

  calculaTotalImporteVentas() {
    let total = 0;
    this.productosVenta.forEach(producto => {
      total =
        total +
        Number.parseFloat(producto.ProductoImporteTotal.replace(',', '.'));
    });
    return this.currency.transform(total, this.divisaSimboloDecimal, this.divisaDecimales, this.divisaPosicion, this.divisaSimbolo);
  }

  calculaHora(fecha) {
    const hora = fecha.split(' ')[1];
    const [h, m, s] = hora.split(':');
    return h + ':' + m;
  }

  calculaFecha(fecha) {
    return fecha.split(' ')[0];
  }

  delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
  }
  addDotsToNumber(number, decimalLength = 2) {
    number = '' + number;
    number.replace('.', ',');
    if (number.includes(',')) {
      // tslint:disable-next-line:prefer-const
      let [integer, decimal] = number.split(',');
      if (integer.length > 3) {
        const integerArray = integer.split('');
        let newInteger = '';
        integerArray.forEach((num, index) => {
          if ((integerArray.length - index) % 3 === 0) {
            newInteger += '.';
          }
          newInteger += num;
        });
        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      } else {
        let newInteger = integer;

        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      }
    } else if (number.length > 3) {
      const integerArray = number.split('');
      let newInteger = '';
      integerArray.forEach((num, index) => {
        if ((integerArray.length - index) % 3 === 0) {
          newInteger += '.';
        }
        newInteger += num;
      });
      let decimal = ',';
      while (decimal.length <= decimalLength) {
        decimal += '0';
        // 
      }
      newInteger += decimal;
      return newInteger;
    }
    // 
    let d = ',';
    for (let i = 0; i < decimalLength; ++i) {
      d += '0';
    }
    return number + d;
  }
}
