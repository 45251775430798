import { Component, OnInit, OnDestroy } from '@angular/core';
import { Entrada } from '../../models/entrada.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { VisorService } from 'src/app/shared/services/visor.service';
import { EntradasService } from '../../services/entradas.service';
import { ConfigurationService } from 'src/app/core';
import { Carrito } from '../../models/carrito.model';
import { VentaService } from '../../services/venta.service';

import * as moment from 'moment';
import { takeWhile, distinctUntilChanged, mergeMap, map, tap } from 'rxjs/operators';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { PagerService } from 'src/app/shared/services/pager.service';
import { NumpadService } from 'src/app/shared/services/numpad.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ticketing-selector-entradas-botonera',
  templateUrl: './selector-entradas-botonera.component.html',
  styleUrls: ['./selector-entradas-botonera.component.scss']
})
export class SelectorEntradasBotoneraComponent implements OnInit, OnDestroy {
  entradaSeleccionada: Entrada;
  carrito: Carrito;
  busquedaForm: FormGroup;

  entradas$;
  entradas;

  idioma = 'es-ES';
  divisaSimboloDecimal: string;
  divisaSimbolo: string;
  divisaDecimales: number;
  divisaPosicion: string;
  esGranDivisa: boolean;

  pager: any;
  pagedItems: any[];

  fecha: any;
  cliente: any;

  private alive = true;
  //declaramos las suscripciones para cerrar los subscribe de todos los observables
  private datosInicioSub: Subscription;
  private carritoSub: Subscription;
  private fechaSeleccionadaSub: Subscription;
  private clienteSeleccionadoSub: Subscription;
  private subscriptions:Subscription[] =[];

  constructor(
    private entradaService: EntradasService,
    private ventaService: VentaService,
    private configurationService: ConfigurationService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private visor: VisorService,
    private pagerService: PagerService,
    private numpadService: NumpadService,
    private formControlService: FormControlService
  ) {
    this.busquedaForm = this.formBuilder.group({
      cliente: '',
      fecha: ''
    });
  }

  ngOnInit() {
    this.entradas$ = this.onChanges();
    this.divisaSimboloDecimal = this.configurationService.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configurationService.divisasValue[0].simbolo;
    this.divisaDecimales = Number(this.configurationService.divisasValue[0].NumeroDecimales)
    this.divisaPosicion = this.configurationService.divisasValue[0].PosicionDivisa;
    this.esGranDivisa = this.configurationService.divisasValue[0].CodIso = "188" ? true: false;
    this.datosInicioSub = this.configurationService.datosInicio.subscribe(conf => (this.idioma = conf.Idioma));
    this.carritoSub = this.ventaService.carrito.subscribe(carrito => (this.carrito = carrito));
    this.fechaSeleccionadaSub = this.ventaService.fechaSeleccionada.pipe(takeWhile(() => this.alive)).subscribe(fecha => {
      this.fecha = fecha;
      this.busquedaForm.controls['fecha'].patchValue(
        moment(this.fecha)
          .locale(this.idioma)
          .format('L')
      );
    });
    this.clienteSeleccionadoSub = this.ventaService.clienteSeleccionado.pipe(takeWhile(() => this.alive)).subscribe(cliente => {
      this.cliente = cliente;
      this.busquedaForm.controls['cliente'].patchValue(cliente ? cliente.NombreCliente : '');
    });
    this.subscriptions.push(this.datosInicioSub);
    this.subscriptions.push(this.fechaSeleccionadaSub);
    this.subscriptions.push(this.carritoSub);
    this.subscriptions.push(this.clienteSeleccionadoSub);
    this.visor.setToBlank();
    // this.entradaService.getEntradasBotonera(this.fecha, this.cliente);
  }

  onChanges() {
    return this.busquedaForm.valueChanges
      .pipe(takeWhile(() => this.alive))
      .pipe(distinctUntilChanged())
      .pipe(
        mergeMap(() => {
          return this.entradaService.getEntradasBotonera(
            this.ventaService.fechaSeleccionadaValue,
            this.ventaService.clienteSeleccionadoValue
          );
        })
      )
      .pipe(
        mergeMap(entradas => {
          const entradasIdsArr = [];
          if(this.entradas){
          entradas.forEach(entrada => {
            if (entrada.TipoProducto === '2') {
              entrada.PromocionEntradas.forEach(item => {
                entradasIdsArr.push(item.TipoEntradaId);
              });
            } else if (entrada.TipoProducto === '1') {
              entradasIdsArr.push(entrada.TipoEntradaId);
            }
          });
        }
          const entradasIds = entradasIdsArr.join();
          return this.entradaService.getRecintosEntrada(entradasIds).pipe(
            map((recintosDisponibles: any) => {
              // 
              if (recintosDisponibles.DatosResult === null) {
                this.entradas = entradas;
                this.setPage(1);
                return this.pagedItems;
              }
              const recintos = recintosDisponibles.DatosResult.RecintosDisponibles;
              if(this.entradas){
              this.entradas.forEach(e => {
                if (e.TipoProducto === '1') {
                  e.recintos = recintos.filter(recinto => {
                    return recinto.TipoEntrada === e.TipoEntradaId;
                  });
                } else if (e.TipoProducto === '2') {
                  e.PromocionEntradas.forEach(item => {
                    e.recintos = recintos.filter(recinto => {
                      return recinto.TipoEntrada === item.TipoEntradaId;
                    });
                  });
                }
              });
            }
              this.entradas = entradas;
              this.setPage(1);
              return this.pagedItems;
            })
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.alive = false;
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  nextDia() {
    const futuribleDate = moment(this.ventaService.fechaSeleccionadaValue).add(1, 'days');
    if (this.ventaService.disabledDaysValue.includes(futuribleDate.format('DD/MM/YYYY'))) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.add(2, 'days')
      );
      return;
    }
    if (this.ventaService.fechaSeleccionadaValue) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.add(1, 'days')
      );
      return;
    }
  }

  backDia() {
    const futuribleDate = moment(this.ventaService.fechaSeleccionadaValue).subtract(1, 'days');
    if (this.ventaService.fechaSeleccionadaValue.isSameOrBefore(moment(), 'day')) {
      return;
    }
    if (this.ventaService.disabledDaysValue.includes(futuribleDate.format('DD/MM/YYYY'))) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.subtract(2, 'days')
      );
      return;
    }
    if (this.ventaService.fechaSeleccionadaValue) {
      this.ventaService.setFechaSeleccionadaValue(
        this.ventaService.fechaSeleccionadaValue.subtract(1, 'days')
      );
      return;
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  getNombreCliente() {
    if (this.ventaService.clienteSeleccionadoValue) {
      return this.ventaService.clienteSeleccionadoValue.NombreCliente;
    }
    return '';
  }

  getCantidadPorEntrada(entrada: Entrada): number {
    if (this.ventaService.carritoValue) {
      return this.ventaService.getCantidadPorEntrada(entrada);
    }
    return 0;
  }

  setPage(page: number): void {
    this.pager = this.pagerService.getPager(this.entradas.length, page, 24);
    this.pagedItems = this.entradas.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.pager.pages = this.pager.pages.filter(num => num <= this.pager.totalPages);
    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }

    this.ventaService.carritoValue.fecha = this.getFechaSeleccionada();
  }

  getFechaSeleccionada(): string {
    if (this.ventaService.fechaSeleccionadaValue) {
      return moment(this.ventaService.fechaSeleccionadaValue)
        .locale(this.idioma)
        .format('YYYY/MM/DD HH:mm:ss');
    }
  }

  seleccionarEntrada(entrada: Entrada): void {
    this.entradaService.setEntradaSeleccionada(entrada);
    this.entradaSeleccionada = this.entradaService.entradaSeleccionada;
    if (entrada.GrupoMotivoId) {
//<< Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables                   
      this.modalService.open('selector-motivos');
      return;

      // //<< Yaribel 20201229  controlamos la llamada al modal solo si los motivos son seleccionables 
      // this.entradaService.comprobarGrupoMotivoDescuentoSeleccionable(entrada.TipoEntradaId).subscribe(motivoSeleccionable =>{
      //   if(motivoSeleccionable)
      //   {
      //     this.modalService.open('selector-motivos');
      //     return;
      //   }
      // });
      // // Yaribel 20201229  controlamos la llamada al modal solo si los motivos son seleccionables        >> 

      //<< Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables                   


    }
    const input = document.getElementById('btn-' + entrada.idTarifa);
    this.numpadService.selected = input;
    this.numpadService.entrada = entrada;
    this.formControlService.setCurrentFormGroup(null, input, input.getAttribute('category'));
    // this.entradaSeleccionada = entrada;
  }
}
